import { bindable, inject }         from 'aurelia-framework';
import { I18N }                     from 'aurelia-i18n';
import { BaseOrderedListViewModel } from 'base-ordered-list-view-model';
import { FilterFormSchema }         from 'modules/map/management/categories/filter-form-schema';
import { AppContainer }             from 'resources/services/app-container';
import { Downloader }               from 'resources/services/downloader';
import { MAPCategoriesRepository }  from './services/repository';

@inject(AppContainer, MAPCategoriesRepository, I18N, Downloader, FilterFormSchema)
export class ListMAPCategories extends BaseOrderedListViewModel {

    listingId = 'map-categories-listing';

    @bindable headerTitle    = 'listing.map.management.categories';
    @bindable newRecordRoute = 'map.management.categories.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.management.categories.index') && super.can([
            'map.management.categories.manage',
            'map.management.categories.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('map.management.categories.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.categories.manage', 'map.management.categories.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('map.management.categories.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.categories.manage', 'map.management.categories.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.categories.manage', 'map.management.categories.delete']),
            },
            options:         [],
            selectable:      true,
            destroySelected: true,
            actionsContextMenu: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'map_category_translations.name',
                    title: 'form.field.description',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    searchable: false,
                    title: 'form.field.status',
                    type:  'label',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'map_categories.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
